<template>
  <div :style="{'width': width,'text-align':textAlignment,'background-image':'url(' + backgroundImage + ')'}" class="banner-box-wrapper">
    <h1 class="title"> {{ title }} </h1>
    <p v-if="!professional" class="subtext">{{ subtext }}</p>

    <button v-if="!professional" type="button" :class="buttonClass" @click="buttonAction">{{ buttonText }}</button>
  </div>
</template>

<script>
export default {
  name: 'BannerBoxWithButton',
  props: {
    title: {
      type: String,
      required: true,
    },
    subtext: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    backgroundImage: {
      type: String,
      default: 'None'
    },
    buttonAction: {
      type: Function,
      default: () => {}
    },

    /** Styling props **/
    // Acceptable values: left/center/...
    textAlignment: {
      type: String,
      required: false,
      default: 'left'
    },
    width: {
      type: String,
      required: false,
      default: '600px'
    },
    buttonClass: {
      type: String,
      required: false,
      default: 'white-button'
    },
    professional: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.banner-box-wrapper {
  border-radius: rem(30);
  color: white;
  padding: rem(40);
  text-align: left;
  background-size: 100% auto;
  // background-repeat: no-repeat;

  .title {
    font-size: rem(28);
    font-weight: 700;
    letter-spacing: 0;
    line-height: rem(37);
    margin-bottom: rem(12);
  }

  .subtext {
    margin-bottom: rem(20);
  }
}
</style>

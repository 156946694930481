<template>
  <div class="waiting-room-wrapper">
    <BannerBoxWithButton
      :title="$t('OVERVIEW.WELCOME')"
      :subtext="$t('OVERVIEW.WELCOME_TEXT')"
      :button-text="$t('CREATE_CLASS_TEXT')"
      text-alignment="center"
      width="100%"
      button-class="pink-button"
      :background-image="require('../../../assets/img/img_background_welcome.png')"
      :button-action="createClass"
      :professional="professional"
    />
    <PageTitle :title="$t('WAITING_ROOM.INSTRUCTION')" class="page-title"/>

    <div v-if="professional">
      <div class="page-section-text section-text"> {{ $t('WAITING_ROOM.INSTRUCTION_TEXT_PROFESSIONAL_ONE') }} </div>
      <div class="page-section-text section-text"> {{ $t('WAITING_ROOM.INSTRUCTION_TEXT_PROFESSIONAL_TWO') }} </div>
      <i18n-t class="page-section-text section-text" keypath="WAITING_ROOM.INSTRUCTION_TEXT_PROFESSIONAL_THREE" tag="div">
        <template #Instructions>
          <router-link class="inline-text link " :to="{name : ROUTE_NAMES_PROFESSIONAL.INSTRUCTIONS}"> {{ $t('WAITING_ROOM.INSTRUCTIONS') }} </router-link>.
        </template>
        <template #Help>
          <router-link class="inline-text link " :to="{name : ROUTE_NAMES_PROFESSIONAL.HELP}"> Help</router-link>.
        </template>
      </i18n-t>
    </div>

    <div v-else-if="approvedTeachers.length > 0">
      <div class="page-section-text section-text"> {{ $t('WAITING_ROOM.INSTRUCTION_TEXT') }} </div>
      <ul class="page-section-text section-text">
        <li v-for="teacher in approvedTeachers" :key="teacher.id" class="teacher-list-item"><span>{{ teacher.firstName }}</span></li>
      </ul>
    </div>
    <div v-else>
      <div class="page-section-text section-text"> {{ $t('WAITING_ROOM.INSTRUCTION_TEXT_ADMIN') }} </div>
    </div>

    <h1 v-if="!professional" class="page-section-title section-title">{{ $t('OVERVIEW.CREATE_CLASS') }}</h1>

    <div v-if="!professional" class="page-section-text section-text inline-section">
      <div class="inline-text"> {{ $t('WAITING_ROOM.CREATE_CLASS_DESCRIPTION_ONE') }} </div>
      <router-link class="inline-text link" :to="{name : ROUTE_NAMES_TEACHER_PORTAL.INSTRUCTIONS}"> {{ " " + $t('WAITING_ROOM.INSTRUCTIONS') + "" }} </router-link>.
      <div class="inline-text"> {{ $t('WAITING_ROOM.CREATE_CLASS_DESCRIPTION_TWO') }} </div>
      <router-link class="inline-text link" :to="{name : ROUTE_NAMES_TEACHER_PORTAL.HELP}"> Help</router-link>
    </div>
    <!--
    <h1 class="page-section-title section-title">{{ $t('WAITING_ROOM.INSTRUCTION_VIDEO') }}</h1>

    <div class="page-section-text section-text inline-section">
      <div class="inline-text"> {{ $t('WAITING_ROOM.INSTRUCTION_VIDEO_TEXT_ONE') }} </div>
      <router-link class="inline-text link" :to="ROUTE_NAMES_TEACHER_PORTAL.HELP"> HELP </router-link>
      <div class="inline-text"> {{ $t('WAITING_ROOM.INSTRUCTION_VIDEO_TEXT_TWO') }} </div>
    </div>
    <VideoPlayer v-bind="instructionVideo" />
-->

  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
//import VideoPlayer from '@/components/elements/video/VideoPlayer'
import BannerBoxWithButton from '@/components/elements/BannerBoxWithButton'
import apiHandler from '@/utils/handlers/ApiHandler'
import { ROUTE_NAMES_TEACHER_PORTAL } from '@/router/modules/teacherPortal'
import { ROUTE_NAMES_PROFESSIONAL } from '@/router/modules/professional'

import { useRouter } from 'vue-router'
import { computed, ref } from '@vue/runtime-core'
import { useStore } from 'vuex'
import LocalDataHandler from '../../../utils/handlers/LocalDataHandler'
import { USER_TYPES } from '@/models/User'

export default {
  name: 'WaitingRoom',
  components: {
    PageTitle,
    BannerBoxWithButton,
    //VideoPlayer
  },
  setup() {
    const router = useRouter()
    const currentProfile = LocalDataHandler.getUser()

    const professional = computed(() => currentProfile.type === USER_TYPES.PROFESSIONAL)
    const approvedTeachers = ref([])

    function retrieveTeacherData() {
      if (!professional.value) {
        apiHandler.get('teacher/get-teacher-requests').then((response) => {
          approvedTeachers.value = response.data.approvedTeachers ?? []
        })
      }
    }


    function createClass() {
      router.push({ name: ROUTE_NAMES_TEACHER_PORTAL.CREATE_CLASS })
    }

    // Call for startup
    retrieveTeacherData()

    const instructionVideo = {
      source: 'https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4',
      videoWidth: '320',
      videoHeight: '240'
    }

    return {
      instructionVideo,
      approvedTeachers,
      createClass,
      professional,

      ROUTE_NAMES_TEACHER_PORTAL,
      ROUTE_NAMES_PROFESSIONAL
    }
    /** FAQ **/
  }

}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.waiting-room-wrapper {
  padding: rem(32) rem(49);
}

.page-title {
  margin-top: rem(32);
  margin-bottom: rem(24);
}

.inline-text{
  display: inline;
}

.inline-section{

}

.section-text {
  line-height: 150%;
  margin-bottom: rem(32);

  .teacher-list-item {
    font-weight: 500;
    padding-left: rem(10);
    font-size: 30px;

    span {
      font-size: 1rem;
      vertical-align: middle;
    }
  }
}

.section-title {
  margin-bottom: rem(12);
}

.link{
  color: #145298;
  font-weight: 1000;
}

/** **/
</style>
